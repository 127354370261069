import {
  createDrawerNavigator,
  DrawerContentScrollView,
  DrawerItemList
} from '@react-navigation/drawer'
import { createStackNavigator } from '@react-navigation/stack'
import {
  NavigationContainer,
  useNavigation,
  useTheme
} from '@react-navigation/native'
import { MaterialIcons, Ionicons } from '@expo/vector-icons'
import {
  ActivityIndicator,
  View,
  TouchableOpacity,
  StyleSheet,
  Text,
  TextInput,
  Image,
  Modal,
  Dimensions,
  Linking,
  Pressable
} from 'react-native'
import React, {
  createContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
  useContext,
  useRef,
  lazy,
  Suspense
} from 'react'
import { RootSiblingParent } from 'react-native-root-siblings'
import * as Network from 'expo-network'
import { LinearGradient } from 'expo-linear-gradient'
import Toast from 'react-native-root-toast'
import { getTranslation, unifiedApiCall } from './api/api'
import AsyncStorage from '@react-native-async-storage/async-storage'

import * as UserStore from './stores/UserStore'

const Drawer = createDrawerNavigator()
const SignedOutStack = createStackNavigator()
const AuthContext = createContext()

const HomeScreen = lazy(() => import('./screens/HomeScreen'))
const TasksScreen = lazy(() => import('./screens/TasksScreen'))
const TrainingsScreen = lazy(() => import('./screens/TrainingsScreen'))
const SupportScreen = lazy(() => import('./screens/SupportScreen'))
const SettingsScreen = lazy(() => import('./screens/SettingsScreen'))
const DocumentsScreen = lazy(() => import('./screens/DocumentsScreen'))
const DetailScreen = lazy(() => import('./screens/DetailScreen'))
const EmployeesScreen = lazy(() => import('./screens/EmployeesScreen'))
const NewEmployeeScreen = lazy(() => import('./screens/NewEmployeeScreen'))
const ImportEmployeesScreen = lazy(() =>
  import('./screens/ImportEmployeesScreen')
)
const AboutScreen = lazy(() => import('./screens/AboutScreen'))

function LandingPage() {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  const usernameRef = useRef(null)
  const passwordRef = useRef(null)

  const { signIn } = useContext(AuthContext)
  const theme = useTheme()

  useEffect(() => {
    usernameRef.current.focus()
  }, [])

  async function login(_username, _password) {
    try {
      // check network state
      const networkState = await Network.getNetworkStateAsync()
      if (!networkState.isConnected) {
        Toast.show('Kein Netzwerk verfügbar', {
          duration: Toast.durations.LONG,
          position: Toast.positions.BOTTOM,
          shadow: true,
          animation: true,
          hideOnPress: true,
          delay: 0,
          backgroundColor: theme.colors.error
        })
        setLoading(false)
        return
      }
      // check password length
      if (_password.length < 6) {
        Toast.show('Passwort muss sechs Zeichen oder länger sein', {
          duration: Toast.durations.LONG,
          position: Toast.positions.BOTTOM,
          shadow: true,
          animation: true,
          hideOnPress: true,
          delay: 0,
          backgroundColor: theme.colors.error
        })
        setLoading(false)
        return
      }

      const token = await UserStore.tryLogin(_username, _password)
      signIn({ token })
      return true
    } catch (e) {
      Toast.show(e, {
        duration: Toast.durations.LONG,
        position: Toast.positions.BOTTOM,
        shadow: true,
        animation: true,
        hideOnPress: true,
        delay: 0,
        backgroundColor: theme.colors.error
      })
      // console.error('login:', e)
      setLoading(false)
      return false
    }
  }

  if (loading) {
    return (
      <View
        style={{
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: theme.colors.background
        }}
      >
        <ActivityIndicator size='large' color={theme.colors.primary} />
      </View>
    )
  }

  return (
    <LinearGradient
      colors={[theme.colors.secondary, theme.colors.primary]}
      style={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Modal
        animationType='none'
        transparent
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(false)
        }}
      >
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <View
            style={[
              styles.modalView,
              {
                backgroundColor: theme.colors.card,
                borderColor: theme.colors.border,
                shadowColor: theme.colors.black
              }
            ]}
          >
            <TextInput
              value={username}
              onChangeText={(text) => setUsername(text)}
              style={[
                styles.textInput,
                { borderColor: theme.colors.border, color: theme.colors.text }
              ]}
              keyboardType='email-address'
              placeholder='E-Mail'
            />
            <TouchableOpacity
              onPress={async () => {
                try {
                  await unifiedApiCall('resetPassword', {
                    userData: { username }
                  })
                  Toast.show('E-Mail versandt', {
                    duration: Toast.durations.LONG,
                    position: Toast.positions.BOTTOM,
                    shadow: true,
                    animation: true,
                    hideOnPress: true,
                    delay: 0,
                    backgroundColor: theme.colors.success
                  })
                  setModalVisible(false)
                } catch (e) {
                  Toast.show(e, {
                    duration: Toast.durations.LONG,
                    position: Toast.positions.BOTTOM,
                    shadow: true,
                    animation: true,
                    hideOnPress: true,
                    delay: 0,
                    backgroundColor: theme.colors.error
                  })
                }
              }}
              style={[
                styles.button,
                {
                  backgroundColor: theme.colors.success,
                  margin: 5,
                  marginBottom: 0
                }
              ]}
            >
              <Text style={{ color: theme.colors.white }}>
                Passwort zurücksetzen
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                setModalVisible(false)
              }}
              style={[
                styles.button,
                {
                  margin: 5,
                  marginBottom: 0,
                  backgroundColor: theme.colors.error
                }
              ]}
            >
              <Text style={{ color: theme.colors.white }}>Schließen</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
      <View
        style={{
          padding: 40,
          borderWidth: 1,
          borderRadius: 5,
          borderColor: theme.colors.border,
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: theme.colors.card
        }}
      >
        <Image
          style={{ width: 256, height: 93, margin: 20 }}
          source={{ uri: UserStore.customer.logoOriginal }}
        />
        <TextInput
          value={username}
          ref={usernameRef}
          onChangeText={(text) => setUsername(text)}
          onKeyPress={async (key) => {
            if (key.key === 'Enter' || key.keyCode === 13) {
              passwordRef.current.focus()
            }
          }}
          style={[
            styles.textInput,
            { borderColor: theme.colors.border, color: theme.colors.text }
          ]}
          keyboardType='email-address'
          placeholder='E-Mail'
        />
        <View
          style={{
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <TextInput
            value={password}
            ref={passwordRef}
            onChangeText={(text) => setPassword(text)}
            onKeyPress={async (key) => {
              if (key.key === 'Enter' || key.keyCode === 13) {
                try {
                  setLoading(true)
                  await login(username, password)
                } catch (e) {
                  // console.error('LandingPage:', e)
                  setLoading(false)
                }
              }
            }}
            style={[
              styles.textInput,
              { borderColor: theme.colors.border, color: theme.colors.text }
            ]}
            keyboardType='default'
            secureTextEntry={!showPassword}
            placeholder='Passwort'
          />
          <TouchableOpacity
            style={{ position: 'absolute', bottom: 12, right: 8 }}
            onPress={() => {
              setShowPassword(!showPassword)
            }}
          >
            <Ionicons
              name={showPassword ? 'eye-off' : 'eye'}
              color={theme.colors.text}
              size={20}
            />
          </TouchableOpacity>
        </View>
        <TouchableOpacity
          onPress={() => {
            setModalVisible(true)
          }}
        >
          <Text style={[styles.link, { color: theme.colors.primary }]}>
            Passwort vergessen?
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={async () => {
            try {
              setLoading(true)
              await login(username, password)
            } catch (e) {
              // console.error('LandingPage:', e)
              setLoading(false)
            }
          }}
          style={[
            styles.button,
            { backgroundColor: theme.colors.primary, marginTop: 20 }
          ]}
        >
          <Text style={{ color: theme.colors.white }}>Login</Text>
        </TouchableOpacity>
        <View
          style={{
            width: '100%',
            borderBottomWidth: 1,
            borderBottomColor: theme.colors.border
          }}
        />
        <TouchableOpacity
          onPress={() => {
            window.open('https://horeca-hero.com/', '_blank')
          }}
          style={[
            styles.button,
            {
              backgroundColor: theme.colors.white,
              borderColor: theme.colors.primary,
              borderWidth: 1,
              marginTop: 20,
              marginVertical: 10
            }
          ]}
        >
          <Text style={{ color: theme.colors.primary }}>
            Horeca HERO Website
          </Text>
        </TouchableOpacity>
      </View>
    </LinearGradient>
  )
}

function CustomDrawerContent(props) {
  return (
    <DrawerContentScrollView {...props}>
      <Image
        source={{ uri: UserStore.customer.logoOriginal }}
        style={{
          width: 1024 / 5,
          height: 370 / 5,
          alignSelf: 'center',
          margin: 10
        }}
      />
      <DrawerItemList {...props} />
    </DrawerContentScrollView>
  )
}

function DrawerComponent() {
  const [width, setWidth] = useState(Dimensions.get('window').width)
  const [tooltip, setTooltip] = useState('')

  const navigation = useNavigation()

  useEffect(() => {
    Dimensions.addEventListener('change', () =>
      setWidth(Dimensions.get('window').width)
    )
    return () =>
      Dimensions.removeEventListener('change', () =>
        setWidth(Dimensions.get('window').width)
      )
  }, [])

  const { signOut } = useContext(AuthContext)
  const theme = useTheme()

  const getComponentFromURL = (_url) => {
    switch (_url) {
      case '/home':
        return HomeScreen
      case '/tasks':
        return TasksScreen
      case '/content?screen=1':
        return TrainingsScreen
      case '/protocols':
        return DocumentsScreen
      case '/employees':
        return EmployeesScreen
      case 'p/profile':
        return SettingsScreen
      case '/content?screen=3':
        return SupportScreen
      case '/about':
        return AboutScreen
      default:
        return HomeScreen
    }
  }

  const getNameFromURL = (_url) => {
    switch (_url) {
      case '/home':
        return 'Home'
      case '/tasks':
        return 'Tasks'
      case '/content?screen=1':
        return 'Trainings'
      case '/protocols':
        return 'Documents'
      case '/employees':
        return 'Employees'
      case 'p/profile':
        return 'Settings'
      case '/content?screen=3':
        return 'Support'
      case '/about':
        return 'About'
      default:
        return '-'
    }
  }

  return (
    <Drawer.Navigator
      backBehavior='history'
      initialRouteName={getComponentFromURL('/home')}
      drawerContent={(props) => {
        return <CustomDrawerContent {...props} />
      }}
      screenOptions={{
        headerTintColor: theme.colors.white,
        drawerActiveBackgroundColor: theme.colors.background,
        drawerActiveTintColor: theme.colors.text,
        headerStyle: {
          backgroundColor: theme.colors.primary
        },
        headerRight: () => {
          return (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                marginHorizontal: 10
              }}
            >
              <Pressable
                onPress={() => {
                  navigation.navigate('Trainings')
                }}
                onHoverIn={() => {
                  setTooltip('trainings')
                }}
                onHoverOut={() => {
                  setTooltip('')
                }}
              >
                <MaterialIcons
                  name='school'
                  size={25}
                  color='#fff'
                  style={{ paddingRight: 15 }}
                />
                {tooltip === 'trainings' && (
                  <Text style={styles.tooltip}>
                    {getTranslation('screen_trainings')}
                  </Text>
                )}
              </Pressable>
              <Pressable
                onPress={() => {
                  navigation.navigate('Tasks')
                }}
                onHoverIn={() => {
                  setTooltip('tasks')
                }}
                onHoverOut={() => {
                  setTooltip('')
                }}
              >
                <MaterialIcons
                  name='date-range'
                  size={25}
                  color='#fff'
                  style={{ paddingRight: 15 }}
                />
                {tooltip === 'tasks' && (
                  <Text style={styles.tooltip}>
                    {getTranslation('screen_tasks')}
                  </Text>
                )}
              </Pressable>
              <Pressable
                onPress={() => {
                  navigation.navigate('Documents')
                }}
                onHoverIn={() => {
                  setTooltip('documents')
                }}
                onHoverOut={() => {
                  setTooltip('')
                }}
              >
                <MaterialIcons
                  name='insert-drive-file'
                  size={25}
                  color='#fff'
                  style={{ paddingRight: 15 }}
                />
                {tooltip === 'documents' && (
                  <Text style={styles.tooltip}>
                    {getTranslation('txt_documents')}
                  </Text>
                )}
              </Pressable>
              {UserStore.isOwner && (
                <Pressable
                  onPress={() => {
                    navigation.navigate('Employees')
                  }}
                  onHoverIn={() => {
                    setTooltip('employees')
                  }}
                  onHoverOut={() => {
                    setTooltip('')
                  }}
                >
                  <MaterialIcons
                    name='people'
                    size={25}
                    color='#fff'
                    style={{ paddingRight: 15 }}
                  />
                  {tooltip === 'employees' && (
                    <Text style={styles.tooltip}>
                      {getTranslation('txt_employees')}
                    </Text>
                  )}
                </Pressable>
              )}
              <TouchableOpacity
                onPress={async () => {
                  signOut()
                  await UserStore.tryLogout()
                }}
                style={{ backgroundColor: theme.colors.error, borderRadius: 5 }}
              >
                <MaterialIcons
                  name='logout'
                  size={25}
                  color={theme.colors.white}
                  style={{ padding: 5 }}
                />
              </TouchableOpacity>
            </View>
          )
        }
      }}
    >
      {UserStore.menuLinks.map((_menuItem) => {
        return (
          <Drawer.Screen
            key={_menuItem.id}
            name={getNameFromURL(_menuItem.url)}
            component={getComponentFromURL(_menuItem.url)}
            options={{
              drawerIcon: () => {
                return (
                  <Ionicons
                    name={_menuItem.visual.substring(
                      _menuItem.visual.lastIndexOf('/') + 1,
                      _menuItem.visual.lastIndexOf('.')
                    )}
                    size={20}
                    color={theme.colors.text}
                  />
                )
              },
              title: _menuItem.label,
              headerTitle: width > 850 ? _menuItem.label : ''
            }}
          />
        )
      })}
      <Drawer.Screen
        key={crypto.randomUUID()}
        name='Detail'
        component={DetailScreen}
        options={{ drawerItemStyle: { display: 'none' } }}
      />
      <Drawer.Screen
        key={crypto.randomUUID()}
        name='NewEmployee'
        component={NewEmployeeScreen}
        options={{ drawerItemStyle: { display: 'none' } }}
      />
      <Drawer.Screen
        key={crypto.randomUUID()}
        name='ImportEmployees'
        component={ImportEmployeesScreen}
        options={{ drawerItemStyle: { display: 'none' } }}
      />
    </Drawer.Navigator>
  )
}

export default function App() {
  const [state, dispatch] = useReducer(
    (prevState, action) => {
      switch (action.type) {
        case 'RESTORE_TOKEN':
          return {
            ...prevState,
            userToken: action.token,
            isLoading: false
          }
        case 'SIGN_IN':
          return {
            ...prevState,
            isSignout: false,
            userToken: action.token
          }
        case 'SIGN_OUT':
          return {
            ...prevState,
            isSignout: true,
            userToken: null
          }
      }
    },
    { isLoading: true, isSignout: false, userToken: null }
  )

  useEffect(async () => {
    const url = await Linking.getInitialURL()
    await UserStore.getCustomer(
      url.substring(url.indexOf('//') + 2, url.indexOf('.'))
    )

    const fetchToken = async () => {
      let userToken = null
      try {
        const response = await AsyncStorage.getItem('@tokens')
        if (response) {
          userToken = JSON.parse(response)
          const tryLogin = await UserStore.tryAutoLogin(userToken)
          if (!tryLogin) {
            userToken = null
          }
        }
      } catch (e) {
        userToken = null
        // console.error('App:', e)
      }
      dispatch({ type: 'RESTORE_TOKEN', token: userToken })
    }

    fetchToken()
  }, [])

  const authContext = useMemo(() => ({
    signIn: (token) => {
      dispatch({ type: 'SIGN_IN', token })
    },
    signOut: () => {
      dispatch({ type: 'SIGN_OUT' })
    }
  }))

  const AppTheme = {
    dark: false,
    colors: {
      primary: UserStore.customer.primaryColor,
      secondary: UserStore.customer.secondaryColor,
      background: 'rgb(242, 242, 242)',
      card: 'rgb(255, 255, 255)',
      text: 'rgb(28, 28, 28)',
      border: 'rgb(204, 204, 204)',
      black: 'rgb(0, 0, 0)',
      white: 'rgb(255, 255, 255)',
      success: 'rgb(37, 155, 36)',
      error: 'rgb(229, 28, 35)',
      warning: 'rgb(243, 186, 68)'
    }
  }

  const linking = {
    config: {
      screens: {
        LandingPage: '',
        Home: 'home',
        Trainings: 'trainings',
        Tasks: 'tasks',
        Documents: 'documents',
        Employees: 'employees',
        NewEmployee: 'employees/new',
        ImportEmployees: 'employees/import',
        Settings: 'settings',
        Support: 'support',
        Detail: 'detail/:id',
        About: 'about'
      }
    }
  }

  if (state.isLoading) {
    return (
      <View
        style={{
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: AppTheme.colors.background
        }}
      >
        <ActivityIndicator size='large' color={AppTheme.colors.primary} />
      </View>
    )
  }

  return (
    <NavigationContainer
      linking={linking}
      theme={AppTheme}
      fallback={HomeScreen}
    >
      <AuthContext.Provider value={authContext}>
        <RootSiblingParent>
          <Suspense
            fallback={
              <View
                style={{
                  flex: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: AppTheme.colors.background
                }}
              >
                <ActivityIndicator
                  size='large'
                  color={AppTheme.colors.primary}
                />
              </View>
            }
          >
            {state.userToken === null ? (
              <SignedOutStack.Navigator>
                <SignedOutStack.Screen
                  name='LandingPage'
                  component={LandingPage}
                  options={{ headerShown: false, title: 'Horeca HERO' }}
                />
              </SignedOutStack.Navigator>
            ) : (
              <DrawerComponent />
            )}
          </Suspense>
        </RootSiblingParent>
      </AuthContext.Provider>
    </NavigationContainer>
  )
}

const styles = StyleSheet.create({
  textInput: {
    width: '100%',
    padding: 10,
    borderRadius: 5,
    borderWidth: 1,
    margin: 5
  },
  button: {
    width: '100%',
    padding: 10,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    margin: 20
  },
  link: {
    fontSize: 15,
    textDecorationLine: 'underline',
    margin: 5,
    marginBottom: 0
  },
  modalView: {
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderRadius: 5,
    padding: 15,
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5
  },
  tooltip: {
    position: 'absolute',
    top: 0,
    right: 50,
    padding: 5,
    paddingHorizontal: 20,
    textAlign: 'center',
    color: '#000',
    backgroundColor: '#fff',
    borderRadius: 5
  }
})
