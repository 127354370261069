import { unifiedApiCall } from '../api/api'

let contentGroups = null

const getContentGroups = async () => {
  try {
    const response = await unifiedApiCall('getContentGroups')
    contentGroups = response

    return Promise.resolve(response)
  } catch (e) {
    // console.error('getContentGroups', e)
    return Promise.reject(e)
  }
}

const getTrainingItems = () => {
  const groups = []

  contentGroups &&
    contentGroups.map((item) => {
      if (item.screen === 1) groups.push(item)
    })

  return groups
}

const getSupportItems = () => {
  const groups = []

  contentGroups &&
    contentGroups.map((item) => {
      if (item.screen === 3) groups.push(item)
    })

  return groups
}

const getHomeItems = () => {
  const groups = []

  contentGroups &&
    contentGroups.map((item) => {
      if (item.screen === 0) groups.push(item)
    })

  return groups
}

const resetContentGroups = () => {
  contentGroups = []
}

export {
  contentGroups,
  getContentGroups,
  resetContentGroups,
  getTrainingItems,
  getSupportItems,
  getHomeItems
}
