import AsyncStorage from '@react-native-async-storage/async-storage'
import { unifiedApiCall, defaultHeaders } from '../api/api'
import environment from '../environment'
import * as ContentStore from './ContentStore'

let user = null
let translations = null
let business = null
let isOwner = null
let menuLinks = []
let navigationKey = null
let openTasksByDay = null
let countries = []
let documents = []
let businessCountries = []
let businessBranches = []
let operatingAreas = []
let employees = []
let employeesWithRetired = []
let dashboardData = null
let languages = []
let openTasksUnfiltered = []
let importableEmployees = []
let customer = {
  primaryColor: 'rgb(42, 53, 126)',
  secondaryColor: 'rgb(29, 37, 88)'
}

const tryLogin = async (_username, _password) => {
  try {
    const params = {
      userData: {
        username: _username,
        password: _password,
        grant_type: 'password'
      }
    }

    const modifedParams = JSON.parse(JSON.stringify(params))
    const response = await unifiedApiCall('userSignIn', modifedParams)
    global.tokens = response
    await AsyncStorage.setItem('@tokens', JSON.stringify(response))

    await requestsAfterLogin()
    return Promise.resolve(response)
  } catch (e) {
    // console.error('tryLogin:', e)
    return Promise.reject(e)
  }
}

const tryAutoLogin = async (_token) => {
  try {
    global.tokens = _token
    const httpResponse = await fetch(`${environment.apiUrl}user/data`, {
      method: 'GET',
      headers: defaultHeaders('getUserData')
    })
    if (!httpResponse.ok) {
      tryLogout()
      throw new Error('token timeout')
    }
    await requestsAfterLogin()
    return Promise.resolve(true)
  } catch (e) {
    // console.error(e)
    return Promise.resolve(false)
  }
}

const requestsAfterLogin = async () => {
  try {
    await updateUserLanguage()
    await updateTranslationsFromBackend()
    await getLatestBusiness()

    if (business) {
      await getOperatingAreas()
      ContentStore.resetContentGroups()
      await ContentStore.getContentGroups()
      await getEmployees()
      await getDashboardData()
      await getOpenTasks()
    }
  } catch (e) {
    // console.error('requestsAfterLogin:', e)
  }
}

const updateUserLanguage = async () => {
  try {
    const response = await unifiedApiCall('getUserData')
    const languageToUse = response.userData.language
      ? response.userData.language
      : 'de'

    const params = {
      userData: {
        birthDay: response.userData.birthDay
          ? response.userData.birthDay
          : null,
        language: languageToUse
      }
    }

    const updateUserData = await unifiedApiCall('updateUserData', params)

    user = updateUserData.userData

    return Promise.resolve(languageToUse)
  } catch (e) {
    // console.error('updateUserLanguage:', e)
    return Promise.reject(e)
  }
}

const updateTranslationsFromBackend = async () => {
  try {
    const response = await unifiedApiCall('getTranslations')
    translations = response.translations
    return Promise.resolve(response.translations)
  } catch (e) {
    // console.error('updateTranslationsFromBackend:', e)
    Promise.reject(e)
  }
}

const getLatestBusiness = async () => {
  // todo: add tablet mode
  try {
    const response = await unifiedApiCall('getLatestBusiness')
    const businessId = response.business.id

    menuLinks = response.links ? response.links : []

    isOwner = !!response.is_owner

    const _business = await unifiedApiCall(
      'getBusinesses',
      {},
      `${environment.apiUrl}business/` + businessId
    )

    business = _business

    return Promise.resolve(_business)
  } catch (e) {
    // console.error('getLatestBusiness:', e)
    return Promise.reject(e)
  }
}

const getOpenTasks = async () => {
  try {
    const now = new Date().getTime() / 1000

    const responseDueAfterNow = await unifiedApiCall(
      'myTasks',
      null,
      `${environment.apiUrl}user/my-tasks?status=new&group_by=day&due_after=${now}`
    )

    const responseDueBeforeNow = await unifiedApiCall(
      'myTasks',
      null,
      `${environment.apiUrl}user/my-tasks?status=new&group_by=day&due_before=${now}`
    )

    const responseUnfiltered = await unifiedApiCall(
      'myTasks',
      null,
      `${environment.apiUrl}user/my-tasks?status=new`
    )

    openTasksUnfiltered = responseUnfiltered
    openTasksByDay = []

    responseDueBeforeNow.map((dateItem) => {
      Object.keys(dateItem).map((date) => {
        const filteredOverdueTasks = dateItem[date].filter((task) => {
          return task.category.id !== '8e0139da-da72-41db-acd1-5a760d3e0041'
        })
        if (filteredOverdueTasks.length > 0) {
          openTasksByDay.push({
            [date]: dateItem[date].filter((task) => {
              return task.category.id !== '8e0139da-da72-41db-acd1-5a760d3e0041'
            })
          })
        }
      })
    })

    responseDueAfterNow.map((dateItem) => {
      Object.keys(dateItem).map((date) => {
        openTasksByDay.push({
          [date]: dateItem[date]
        })
      })
    })

    return Promise.resolve(responseUnfiltered)
  } catch (e) {
    // console.error('getOpenTasks:', e)
    return Promise.reject(e)
  }
}

const getOpenTasksUnfiltered = async () => {
  try {
    const responseUnfiltered = await unifiedApiCall(
      'myTasks',
      null,
      `${environment.apiUrl}user/my-tasks?status=new`
    )

    openTasksUnfiltered = responseUnfiltered

    return Promise.resolve(responseUnfiltered)
  } catch (e) {
    // console.error('getOpenTasksUnfiltered:', e)
    return Promise.reject(e)
  }
}

const updateNavigationKey = (_key) => {
  navigationKey = _key
}

const getCountries = async () => {
  // todo: add id
  try {
    const response = await unifiedApiCall('getCountries')
    countries = response

    return Promise.resolve(response)
  } catch (e) {
    // console.error('getCountries:', e)
    return Promise.reject(e)
  }
}

const getDocuments = async (
  _startDate,
  _endDate,
  _operatingArea,
  _user,
  _zip = false
) => {
  try {
    const params = {
      start_date: _startDate,
      end_date: _endDate,
      operating_area: _operatingArea,
      user: _user || '',
      zip: _zip
    }
    const response = await unifiedApiCall('getDocuments', params)
    documents = response.documents || []

    return Promise.resolve(response)
  } catch (e) {
    // console.error('getDocuments:', e)
    return Promise.reject(e)
  }
}

const getBusinessCountries = async () => {
  try {
    const response = await unifiedApiCall('getBusinessCountries')
    businessCountries = response
    return Promise.resolve(response)
  } catch (e) {
    // console.error('getBusinessCountries:', e)
    return Promise.reject(e)
  }
}

const getBusinessBranches = async () => {
  try {
    const response = await unifiedApiCall('getBusinessBranches')
    businessBranches = response.business_branches

    return Promise.resolve(response.business_branches)
  } catch (e) {
    // console.error('getBusinessBranches:', e)
    return Promise.reject(e)
  }
}

const getOperatingAreas = async () => {
  try {
    const response = await unifiedApiCall('getOperatingAreas')
    operatingAreas = response.operating_areas

    return Promise.resolve(response.operating_areas)
  } catch (e) {
    // console.error('getOperatingAreas:', e)
    return Promise.reject(e)
  }
}

const getEmployees = async () => {
  try {
    const response = await unifiedApiCall('getEmployees')
    employees = response.users

    return Promise.resolve(response.users)
  } catch (e) {
    // console.error('getEmployees:', e)
    return Promise.reject(e)
  }
}

const getEmployeesWithRetired = async () => {
  try {
    const response = await unifiedApiCall('getEmployeesWithRetired')
    employeesWithRetired = response.users

    return Promise.resolve(response.users)
  } catch (e) {
    // console.error('getEmployeesWithRetired:', e)
    return Promise.reject(e)
  }
}

const getDashboardData = async () => {
  try {
    const response = await unifiedApiCall('getDashboardData')
    dashboardData = response.user_trainings

    return Promise.resolve(response.user_trainings)
  } catch (e) {
    // console.error('getDashboardData:', e)
    return Promise.reject(e)
  }
}

const getLanguages = async () => {
  try {
    const response = await unifiedApiCall('getLanguages')
    languages = response.languages

    return Promise.resolve(response.languages)
  } catch (e) {
    // console.error('getLanguages:', e)
    return Promise.reject(e)
  }
}

const updateUserData = async (_data) => {
  try {
    const response = await unifiedApiCall('getUserData')
    const languageToUse = _data.language ? _data.language : 'de'

    const params = {
      userData: {
        ..._data,
        birthDay: response.userData.birthDay
          ? response.userData.birthDay
          : null,
        language: languageToUse
      }
    }

    const updateUserData = await unifiedApiCall('updateUserData', params)

    user = updateUserData.userData

    return Promise.resolve(updateUserData.userData)
  } catch (e) {
    // console.error('updateUserData:', e)
    return Promise.reject(e)
  }
}

const createEmployee = async (_employee) => {
  try {
    const response = await unifiedApiCall('createUserCrmAndUser', _employee)

    return Promise.resolve(response)
  } catch (e) {
    // console.error('createEmployee:', e)
    return Promise.reject(e)
  }
}

const getImportableEmployees = async () => {
  try {
    const response = await unifiedApiCall(
      'getImportableUsers',
      null,
      `${environment.apiUrl}users-hgc/${business.id}`
    )

    importableEmployees = response.users

    return Promise.resolve(response.users)
  } catch (e) {
    // console.error('getImportableEmployees:', e)
    return Promise.reject(e)
  }
}

const getCustomer = async (_url) => {
  try {
    const response = await unifiedApiCall(
      'getCustomCI',
      null,
      `${environment.apiUrl}business-ci/` + _url
    )

    customer = response

    return Promise.resolve(response)
  } catch (e) {
    // console.error('getCustomer:', e)
    return Promise.reject(e)
  }
}

const tryLogout = async () => {
  try {
    await AsyncStorage.removeItem('@tokens')

    global.tokens = null

    ContentStore.resetContentGroups()
    user = null
    translations = null
    business = null
    isOwner = null
    menuLinks = null
    navigationKey = null
    openTasksByDay = null
    countries = null
    documents = null
    businessCountries = null
    businessBranches = null
    operatingAreas = null
    employees = null
    dashboardData = null
    languages = null

    return true
  } catch (e) {
    // console.error('tryLogout', e)
    return false
  }
}

export {
  tryLogin,
  tryAutoLogin,
  requestsAfterLogin,
  tryLogout,
  user,
  translations,
  business,
  getLatestBusiness,
  isOwner,
  menuLinks,
  navigationKey,
  updateNavigationKey,
  openTasksByDay,
  getOpenTasks,
  countries,
  getCountries,
  documents,
  getDocuments,
  businessCountries,
  getBusinessCountries,
  businessBranches,
  getBusinessBranches,
  operatingAreas,
  getOperatingAreas,
  employees,
  getEmployees,
  employeesWithRetired,
  getEmployeesWithRetired,
  dashboardData,
  getDashboardData,
  languages,
  getLanguages,
  updateUserData,
  createEmployee,
  openTasksUnfiltered,
  importableEmployees,
  getImportableEmployees,
  getOpenTasksUnfiltered,
  customer,
  getCustomer
}
