import Constants from 'expo-constants'

const apiVersion = '/api/v1/'

const mufKey = 'marlis_demo_key'

const ENV = {
  dev: {
    // apiUrl: `https://dev.horeca-hero.com${apiVersion}`
    apiUrl: `https://app.horeca-hero.com${apiVersion}`
  },
  prod: {
    apiUrl: `https://app.horeca-hero.com${apiVersion}`
  }
}

function getEnvVars (env = '') {
  if (typeof env !== 'string') {
    env = ''
  }
  if (env.indexOf('dev') !== -1) return ENV.dev
  if (env.indexOf('prod') !== -1) return ENV.prod

  return ENV.dev
}

function getSlug () {
  if (Object.prototype.hasOwnProperty.call(Constants.manifest, 'slug')) {
    return Constants.manifest.slug
  } else {
    return 'common'
  }
}

export default {
  apiUrl: getEnvVars(Constants.manifest.releaseChannel).apiUrl,
  slug: getSlug(),
  mufKey
}
