import * as Network from 'expo-network'
import environment from '../environment'
import * as UserStore from '../stores/UserStore'

const clientSecret = 'MrecYXS8zCYjxwULYnOyx9pJa3DV86qbfeTZy9YC'
const clientId = 1

const mufKey = environment.mufKey

const urlsForMethod = {
  userSignIn: `${environment.apiUrl}authenticate`,
  userSignOut: `${environment.apiUrl}user/logout`,
  getUserData: `${environment.apiUrl}user/data`,
  updateUserData: `${environment.apiUrl}user/data`,
  getTranslations: `${environment.apiUrl}translations`,
  getLatestBusiness: `${environment.apiUrl}user-group/get-latest-business`,
  myTasks: `${environment.apiUrl}user/my-tasks`,
  getContentGroups: `${environment.apiUrl}content/group`,
  getCountries: `${environment.apiUrl}countries/europe`,
  getDocuments: `${environment.apiUrl}user-documents`,
  getBusinesses: `${environment.apiUrl}business`,
  getBusinessCountries: `${environment.apiUrl}countries`,
  getBusinessBranches: `${environment.apiUrl}business-branch`,
  updateBusiness: `${environment.apiUrl}business`,
  getOperatingAreas: `${environment.apiUrl}operating-areas`,
  completeTask: `${environment.apiUrl}content/response/complete`,
  getEmployees: `${environment.apiUrl}tenant-users`,
  getEmployeesWithRetired: `${environment.apiUrl}tenant-users/with_retired`,
  editEmployee: `${environment.apiUrl}users-crm-update`,
  deleteEmployee: `${environment.apiUrl}users-crm-remove`,
  getDashboardData: `${environment.apiUrl}tasks-dashboard`,
  getLanguages: `${environment.apiUrl}languages`,
  resetPassword: `${environment.apiUrl}password/forgot`,
  createUserCrmAndUser: `${environment.apiUrl}users-crm-with-user-create`,
  checkUsername: `${environment.apiUrl}check-user-name`,
  getImportableUsers: `${environment.apiUrl}users-hgc`,
  getCustomCI: `${environment.apiUrl}business-ci`,
  ignoreImportableUser: `${environment.apiUrl}users-hgc-ignore`
}

const httpGetMethods = {
  getUserData: true,
  userSignOut: true,
  getTranslations: true,
  getLatestBusiness: true,
  myTasks: true,
  getContentGroups: true,
  getCountries: true,
  getBusinesses: true,
  getBusinessCountries: true,
  getBusinessBranches: true,
  getOperatingAreas: true,
  getEmployees: true,
  getDashboardData: true,
  getLanguages: true,
  getImportableUsers: true,
  getCustomCI: true,
  ignoreImportableUser: true,
  getEmployeesWithRetired: true
}

const httpPatchMethods = {
  updateBusiness: true,
  editEmployee: true
}

const httpDeleteMethods = {
  deleteEmployee: true
}

const dontSendAccessToken = {
  userSignIn: true,
  resetPassword: true,
  getCustomCI: true
}

const unifiedApiCall = async (_method, _params, _overwrite) => {
  let httpJsonBody = null

  if (_params) {
    const params = {
      ..._params
    }

    // add default data
    if (params.userData) {
      params.userData.client_id = clientId
      params.userData.client_secret = clientSecret
      params.userData.scope = '*'
      params.userData.muf_key = mufKey
    }
    httpJsonBody = {
      jsonrpc: '2.0',
      method: _method,
      params,
      id: 1
    }
  }

  const url = _overwrite || urlsForMethod[_method]
  try {
    const networkState = await Network.getNetworkStateAsync()
    if (networkState && !networkState.isConnected) throw new Error('no network')

    const fetchArguments = {
      method: 'POST',
      headers: defaultHeaders(_method)
    }

    if (httpGetMethods[_method]) {
      fetchArguments.method = 'GET'
    }

    if (httpPatchMethods[_method]) {
      fetchArguments.method = 'PATCH'
    }

    if (httpDeleteMethods[_method]) {
      fetchArguments.method = 'DELETE'
    }

    if (httpJsonBody && fetchArguments.method === 'POST') {
      fetchArguments.body = JSON.stringify(httpJsonBody)
    }

    if (
      httpJsonBody &&
      (fetchArguments.method === 'POST' ||
        fetchArguments.method === 'PATCH' ||
        fetchArguments.method === 'DELETE')
    ) {
      fetchArguments.body = JSON.stringify(httpJsonBody)
    }

    // global fetch

    const httpResponse = await fetch(url, fetchArguments)
    if (httpResponse.status === 503) {
      throw new Error('maintenance')
    }

    const responseJson = await getGuaranteedJsonFromResponse(httpResponse)
    throwCleanErrorIfInvalid(_method, httpResponse, responseJson)
    return Promise.resolve(responseJson.result)
  } catch (e) {
    // console.error('ERROR: used url: ' + url)
    let errormessage
    if (typeof e === 'string') {
      errormessage = e
    } else if (typeof e === 'object' && e.constructor === Object) {
      errormessage = Object.values(e)[0][0]
    } else errormessage = e.toString()
    return Promise.reject(errormessage)
  }
}

const defaultHeaders = (_method) => {
  const headers = new Headers()
  headers.append('Content-Type', 'application/json')
  headers.append('Accept', 'application/json')
  if (dontSendAccessToken[_method]) {
    return headers
  }
  if (
    _method !== 'userSignIn' ||
    _method !== 'resetPassword' ||
    _method !== 'getCustomCI'
  ) {
    if (!global.tokens.access_token) {
      // console.error('!global.tokens.access_token')
      const _err =
        'Whats wrong with your code? You try to execute "' +
        _method +
        '", BUT there are no tokens in store!'
      throw _err
    }
    headers.append('Authorization', 'Bearer ' + global.tokens.access_token)
  }
  return headers
}

const getGuaranteedJsonFromResponse = async (_response) => {
  try {
    const json = await _response.json()
    return Promise.resolve(json)
  } catch (e) {
    const json = {}
    json.error = {}
    if (typeof e === 'string' && e.length > 0) {
      json.error.message = e
    } else {
      json.error.message = 'Error from Server not defined'
    }
    return Promise.resolve(json)
  }
}

const throwCleanErrorIfInvalid = (_method, _httpResponse, _json) => {
  // we don't need to care about the http-code
  // we only need to check, if we have a json.success response

  if (
    typeof _json.result !== 'object' &&
    _json.result !== 'success' &&
    _httpResponse.status !== 200
  ) {
    /* console.error(
      consolePrefix() +
        'ERROR: api:http status ' +
        _httpResponse.status +
        ' ' +
        _method +
        '\n',
      JSON.stringify(_json)
    ) */

    // 10:52:32:526ERROR: api:http status 500userSignIn
    // {"jsonrpc":"2.0","error":{"code":104,"message":"no such account","data":"no such account"},"id":1}

    if (_json.error && _json.error.message) {
      throw _json.error.message
    } else if (_json.message) {
      throw _json.message
    } else if (_json.exception) {
      // {"message":"","exception":"Symfony\\Component\\HttpKernel\\Exception\\NotFoundHttpException"
      throw _json.exception
    } else {
      throw new Error('unknown error')
    }
  }
}

const getTranslation = (key) => {
  if (typeof key !== 'string' || key.length < 2) {
    // console.warn('ERROR: translation key is invalid #' + key + '#')
    return ''
  }

  // first from backend
  if (
    UserStore.translations !== null &&
    Object.prototype.hasOwnProperty.call(UserStore.translations, key)
  ) {
    return UserStore.translations[key]
  }
}

export { unifiedApiCall, getTranslation, defaultHeaders }
